import React from "react";
import { graphql, PageProps } from "gatsby";
import SEO from "@shared/seo";
import { Query, SanityBrandConnection } from "@graphql-types";
import Sections from "@shared/sections";
import { Hero } from "@global";
import Layout from "@shared/layout";

interface Data extends Query {
  altRegions: SanityBrandConnection;
}

interface Props extends PageProps {
  data: Data;
}

export default function CareersPageTemplate({ data }: Props) {
  if (data.sanityPageCareers == null) return null;

  const { sanityPageCareers, altRegions } = data;
  const { sections, hero } = sanityPageCareers;
  const altRegionsOrganised = altRegions.nodes.map(alt => alt.region);

  return (
    <>
      <SEO
        seoData={sanityPageCareers?.seo}
        slug={sanityPageCareers?.slug?.current as string}
        alternateRegions={altRegionsOrganised}
        currentIban={sanityPageCareers?.region?.iban}
      />
      <Layout>
        <Hero data={hero} useFullHeight />
        {sections && sections.map(section => <Sections key={section?._key} data={section} />)}
      </Layout>
    </>
  );
}

export const query = graphql`
  query CareersQuery($slug: String, $iban: String) {
    sanityPageCareers(slug: { current: { eq: $slug } }, region: { iban: { eq: $iban } }) {
      seo {
        ...sanitySeo
      }
      title
      region {
        iban
      }
      hero {
        ...sanityHero
      }
      slug {
        current
      }
      sections {
        ...sanitySections
        ... on SanityCareersList {
          _key
          _type
          marginBottom
        }
      }
    }
    altRegions: allSanityPageCareers(
      filter: { slug: { current: { eq: $slug } }, isHidden: { ne: true } }
    ) {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
